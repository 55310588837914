import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, Button, Spinner, Spacing, Separator, Counter, Alert, ScreenSpinner, Accordion, PanelHeaderButton, Badge, PanelHeaderContent, ActionSheet, PanelHeaderContext, ActionSheetItem, Pagination, Link, HorizontalScroll, Group, MiniInfoCell, Search, SimpleCell, IconButton, ButtonGroup, HorizontalCell } from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon24Filter, Icon12Chevron, Icon20ErrorCircleFillYellow, Icon20CheckCircleFillGreen, Icon20ViewOutline  } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);

const [data, setData] = useState(null);
const [filter, setFilter] = useState([]);

useEffect(() => {
	Init();
}, []);

useEffect(() => {
	setFilter(params?.filter);
}, [params]);

useEffect(() => {
	setIsLoading(true);
	props.api({'filter': JSON.stringify(filter), 'method': 'Finance.Get.General'}, 'GetRecordModel').then((REQ) => {
	  if (REQ.status === 200) {
	  	setData(REQ.data);
	  	setIsLoading(false);
			props.popout();
	  }
  });
}, [filter, currentPage]);

function Init() {
	setIsLoading(true);
	props.popout(<ScreenSpinner></ScreenSpinner>);
	props.api({'filter': JSON.stringify(filter), 'method': 'Finance.Get.General'}, 'GetRecordModel').then((REQ) => {
	  if (REQ.status === 200) {
	  	setData(REQ.data);
	  	setIsLoading(false);
			props.popout();
	  }
	});
};



return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Отчёты</PanelHeader>

		<Group>
      <Search before={isLoading === true ? <Spinner style={{justifyContent: 'unset'}} size="small"/> : <Icon16SearchOutline />} icon={<Icon24Filter  onClick={() => routeNavigator.push('/sorting/filter', {state: {data: filter, options: data?.request?.options} })} />} onChange={(event) => setFilter({...filter, _search: event.target.value})} />
			
			{data?.request?.totalPages > 1 &&
				<>
					<Spacing size={24}><Separator /></Spacing>
					<div style={{display: 'flex', justifyContent: 'space-around'}}>
						<HorizontalScroll showArrows="false">
							<Pagination onChange={(page) => setCurrentPage(page)} currentPage={currentPage}  totalPages={data?.request?.totalPages} />
						</HorizontalScroll>
					</div>
				</>
			}
    </Group>

		<Group description={<div style={{marginTop: '17px'}}>{"Приход за выбранный месяц: 53852₽ = 53852₽"}</div>}>

			<Accordion open>
		        <Accordion.Summary iconPosition="before">Отчёты администратора</Accordion.Summary>
		        <Accordion.Content>
			        <HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
						<table width="100%">
							<tbody>

								<tr>
									<td className="Table--TD_head"></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Роль</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Имя</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Телефон</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Приход</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Оплата</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Удержание</Button></td>
									<td className="Table--TD_head"><Button size="m" mode="link">Остаток</Button></td>
									<td className="Table--TD_head"></td>
								</tr>

								{data?.request?.item?.administrator?.map((item, key) => {
									return  <tr key={key}>
										<td className="lineTable">{item.remains > 7000 ? <Icon20ErrorCircleFillYellow/> : <Icon20CheckCircleFillGreen/>}</td>
										<td className="lineTable">{item.post}</td>
										<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.name_recipient}</Button></td>
										<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.phone_recipient?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}</Button></td>
										<td className="lineTable">{item.coming} ₽</td>
										<td className="lineTable">{item.payment} ₽</td>
										<td className="lineTable">{item.retention} ₽</td>
										<td className="lineTable">{item.remains} ₽</td>
										<td className="lineTable">
											<ButtonGroup mode="horizontal" gap="m">
									    		<IconButton >
													<Icon20ViewOutline/>
												</IconButton>
											</ButtonGroup>
										</td>
									</tr>
								})}

							</tbody>	
						</table>
					</HorizontalScroll>
		        </Accordion.Content>
	      	</Accordion>

			<Accordion open>
		        <Accordion.Summary iconPosition="before">Отчёты менеджера</Accordion.Summary>
		        <Accordion.Content>
			        <HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
						<table width="100%">
							<tbody>

								<tr>
									<td className="Table--TD_head"></td>
									<td className="Table--TD_head">Роль</td>
									<td className="Table--TD_head">Имя</td>
									<td className="Table--TD_head">Телефон</td>
									<td className="Table--TD_head">Приход</td>
									<td className="Table--TD_head">Оплата</td>
									<td className="Table--TD_head">Удержание</td>
									<td className="Table--TD_head">Остаток</td>
									<td className="Table--TD_head"></td>
								</tr>

								{data?.request?.item?.manager?.map((item, key) => {
									return  <tr key={key}>
										<td className="lineTable">{item.remains > 7000 ? <Icon20ErrorCircleFillYellow/> : <Icon20CheckCircleFillGreen/>}</td>
										<td className="lineTable">{item.post}</td>
										<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.name_recipient}</Button></td>
										<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.phone_recipient?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}</Button></td>
										<td className="lineTable">{item.coming} ₽</td>
										<td className="lineTable">{item.payment} ₽</td>
										<td className="lineTable">{item.retention} ₽</td>
										<td className="lineTable">{item.remains} ₽</td>
										<td className="lineTable">
											<ButtonGroup mode="horizontal" gap="m">
									    		<IconButton >
													<Icon20ViewOutline/>
												</IconButton>
											</ButtonGroup>
										</td>
									</tr>
								})}

							</tbody>	
						</table>
					</HorizontalScroll>
		        </Accordion.Content>
	      	</Accordion>

		</Group>

		{props.Footer}

	</Panel>
)};