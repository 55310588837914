import React, { useState, useEffect } from "react";
import { ModalPage, ModalPageHeader, Separator, Group, FormItem, Textarea, SubnavigationBar, SubnavigationButton, Button, Snackbar, Avatar } from '@vkontakte/vkui';
import { Icon16Done } from '@vkontakte/icons';
import axios from 'axios';

export function Modal(props) {

const [data, setData] = useState([]);
const [snackbar, setSnackbar] = React.useState(null);

useEffect(() => {
  var formData = new FormData();
  formData.append('token', localStorage.getItem('STUA'));
  formData.append('id', null);
  formData.append('method', 'User.Get.MessageTemplates');
  axios.post('https://impuls200.ru/views/ajax/api/backoffice/GetRecordModel.php', formData).then((REQ) => {
    if (REQ.status === 200) {
      setData(REQ.data?.request?.item);
    }
  });
}, []);

function SaveData() {

}

const CopyText = (text) => {
  navigator.clipboard.writeText(text);
  if (snackbar) return;
  setSnackbar(
    <Snackbar
      subtitle={text}
      onClose={() => setSnackbar(null)}
      before={
        <Avatar size={24} style={{ background: 'var(--vkui--color_background_accent)' }}>
          <Icon16Done fill="#fff" width={14} height={14} />
        </Avatar>
      }
    >
      {'Тег ' + text + ' скопирован'} 
    </Snackbar>,
  );
};

return (
  <ModalPage header={<ModalPageHeader>Шаблоны сообщений</ModalPageHeader>} id={props.id}>
    <Separator />
    <Group>
      <SubnavigationBar>
          <SubnavigationButton onClick={() => CopyText('[ИмяКлиента]')} mode="outline">Имя клиента</SubnavigationButton>
          <SubnavigationButton onClick={() => CopyText('[СсылкаФото]')} mode="outline">Сслыка на фото</SubnavigationButton>
          <SubnavigationButton onClick={() => CopyText('[СсылкаВыбора]')} mode="outline">Ссылка на выбор</SubnavigationButton>
          <SubnavigationButton onClick={() => CopyText('[ДатаФотосессии]')} mode="outline">Дата фотосессии</SubnavigationButton>
      </SubnavigationBar>
      <FormItem top="Шаблон сообщений - Сортировки (стандартный)">
        <Textarea key={data.Sorting_Standart ? 'notLoadedYet' : 'loaded'} name="Sorting_Standart" defaultValue={data.Sorting_Standart} />
      </FormItem>
      <FormItem top="Шаблон сообщений - Сортировки (забытые)">
        <Textarea key={data.Sorting_Forgotten ? 'notLoadedYet' : 'loaded'} name="Sorting_Forgotten" defaultValue={data.Sorting_Forgotten} />
      </FormItem>
      <Separator />
      <SubnavigationBar>
          <SubnavigationButton onClick={() => CopyText('[ИмяКлиента]')} mode="outline">Имя клиента</SubnavigationButton>
          <SubnavigationButton onClick={() => CopyText('[ДатаФотосессии]')} mode="outline">Дата фотосессии</SubnavigationButton>
          <SubnavigationButton onClick={() => CopyText('[ВремяФотосессии]')} mode="outline">Время фотосессии</SubnavigationButton>
      </SubnavigationBar>
      <FormItem top="Шаблон сообщений - События">
        <Textarea key={data.Events_Standart ? 'notLoadedYet' : 'loaded'} name="Events_Standart" defaultValue={data.Events_Standart} />
      </FormItem>
      <FormItem>
          <Button size="l" onClick={() => SaveData()} stretched>
            Сохранить
          </Button>
      </FormItem>
    </Group>
    {snackbar}
  </ModalPage>
)};