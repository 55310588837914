import React, { useState, useEffect, useCallback } from "react";
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
import { Panel, PanelHeader, ModalPage, ModalPageHeader, CustomSelect, Text, PanelHeaderClose, NativeSelect, PanelHeaderButton, Cell, Progress, Avatar,File, CustomSelectOption, Group, Button, FormLayout, FormItem, Input, FormLayoutGroup, Checkbox } from '@vkontakte/vkui';
import { Icon28StoryAddOutline  } from '@vkontakte/icons';

import axios from 'axios';
import mask from '../../media/mask.png';

const WaterMarkkk = require('watermark-on-image');

export function App(props) {

const routeNavigator = useRouteNavigator();
const [progressUpload, setProgressUpload] = useState(0);
const [successUpload, setSuccessUpload] = useState(0);
const [totalFiles, setTotalFiles] = useState(0);
const [files, setFiles] = useState();

function base64toFile(base64String, filename) {
  const binaryString = atob(base64String);
  const uint8Array = Array.from(binaryString, (char) => char.charCodeAt(0));
  const blob = new Blob([uint8Array], { type: 'application/octet-stream' });
  const file = new File([blob], filename, { type: 'application/octet-stream' });
  
  return file;
}

const b64toBlob = (b64Data, contentType='image/webp', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const getBlob = (b64Data) => {
  return URL.createObjectURL(b64Data);
}

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const UploadFiles = async (e) => {

	setSuccessUpload(0);
	setProgressUpload(0);
	setTotalFiles(e.target.files.length);

	const imgS = new WaterMarkkk.WaterMarkkk();
	var formData = new FormData();
	var files = Array();


		//console.log(e.target.files[i]);

		       

  	
// for (var i = 0; i < e.target.files.length; i++) {

// 	const img = new Image();
//    files.push(URL.createObjectURL(e.target.files[i]));

    	
// }

var some_function = async function (files) {
  return new Promise(async function (resolve, reject) {
			await imgS.loadSrc(files).markImage(mask).getImage('webp', 0.9, { width: 800, height: 1200 }).then( (imgs) => {
					//console.log(imgs[0]);
		        //URL.revokeObjectURL(file);
						

				    imgs.forEach(img => {
				    	setSuccessUpload(counter => counter +1);
				    	//console.log(img);
				    });
				    resolve(true);
			});
  });
};

// (async function () {
// for (var i = 0; i < e.target.files.length; i++) {

// }
// }) ();


	var arrBlob = [];
	Array.from(e.target.files).forEach(file => {
		arrBlob.push(URL.createObjectURL(file));
	});
	some_function(arrBlob).then((res) => {});

		/*await some_function(files);
		/*.then((res) => {
			// if (res === true) {
			// 	setSuccessUpload(counter => counter +1);
			// }
		})*/

console.log(arrBlob);
	//console.log(files);

	// imgS.loadSrc(files).markImage(mask).getImage('webp', 0.9, { width: 800, height: 1200 }).then(async (imgs) => {
	// console.log(imgs[0]);
	// });



			//  imgS.loadSrc(URL.createObjectURL(e.target.files[i])).markImage(mask).getImage('webp', 0.9, { width: 800, height: 1200 }).then(async (imgs) => {

			 	
			//  		const blob = b64toBlob(imgs[0].replace(/^data:image\/[a-z]+;base64,/, ""));
			//  		formData.append('file', blob);
			//     setSuccessUpload(counter => counter +1);
			

			// 	});

					 	
			
			// //var formData = new FormData();
	    // //formData.append('file',blob);
	    // formData.append('token', localStorage.getItem('STUA'));
			// formData.append('key', 'bjbj');
			// setSuccessUpload(counter => counter +1);
			// axios.post('https://impuls200.ru/views/ajax/api/backoffice/photosets/UploadFile.php', formData).then((REQ) => {
		  //   if (REQ.status === 200) {
		  //   	//setSuccessUpload(counter => counter +1);
		  //   }
		  // });

		
};

useEffect(() => {
	setProgressUpload(Math.floor((successUpload/totalFiles)*100));
}, [successUpload]);


return (
  <Panel id={props.id}>
		<PanelHeader before={<PanelHeaderButton onClick={(e) => routeNavigator.push('/photosets/add/')}><Icon28StoryAddOutline /></PanelHeaderButton>} after={props.PanelHeaderAfterProfile}>Загрузка файлов</PanelHeader>
		<Group>
			<FormItem top="Интенсивность штампа">
        <NativeSelect name="transp_watermark">
          <option value="m">Слабый 8%</option>
          <option value="f">Средний 25%</option>
          <option value="f">Не прозрачный 60%</option>
        </NativeSelect>
      </FormItem>
      <FormItem  top="Загрузите превью">
        <File value="" onChange={(e) => UploadFiles(e)} accept=".jpg, .jpeg" multiple size="l" mode="secondary" />
      </FormItem>
      <FormItem id="progresslabel" top="Прогресс загрузки">
        <Progress aria-labelledby="progresslabel" value={progressUpload} />
      </FormItem>
    </Group>
  </Panel>
)};
