import React, { useState, useEffect } from "react";
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, ScreenSpinner, Group, Div, SegmentedControl, Search, PanelHeaderButton, HorizontalScroll, IconButton, ButtonGroup, Button, Spinner, Spacing, Pagination, Separator  } from '@vkontakte/vkui';
import { Icon20WriteOutline, Icon24List, Icon16GridOfFour, Icon24Filter, Icon28StoryAddOutline, Icon12Chevron, Icon16SearchOutline  } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [data, setData] = useState(null);
const [filter, setFilter] = useState(params?.filter);
const [snackbar, setSnackbar] = React.useState(null);
const [modeView, setModeView] = React.useState('list');
const [groupData, setGroupData] = useState([]);

useEffect(() => {
	Init();
}, []);

useEffect(() => {
	setFilter(params?.filter);
}, [params]);

useEffect(() => {
	setIsLoading(true);
  props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'events'}, 'GetRecordsModel').then((REQ) => {
    if (REQ.status === 200) {
    	setData(REQ.data);
    	setIsLoading(false);
			props.popout();
    }
	});
}, [filter, currentPage]);

function Init() {
	setIsLoading(true);
	props.popout(<ScreenSpinner></ScreenSpinner>);
	props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'events'}, 'GetRecordsModel').then((REQ) => {
    if (REQ.status === 200) {
    	setData(REQ.data);
    	setIsLoading(false);
			props.popout();
    }
	});
};

useEffect(() => {
	var date = [];
	var photographers = [];
	var items = [];
	data?.request?.items.reduce((item, { DateF, Photographer }) => {
	  date.push(DateF);
	  photographers.push(Photographer);
	}, {});

	items["date"] = [];
	items["photographers"] = [];

	items["date"].push(date.filter((x, i, a) => a.indexOf(x) === i).sort(((a, b) => new Date(a) - new Date(b))));
	items["photographers"].push(photographers.filter((x, i, a) => a.indexOf(x) === i).sort((a, b) => a.localeCompare(b)));
	setGroupData(items);
}, [data?.request?.items]);

function GetColor(item) {
 if (item.ClientsEvent > 0 && item.ClientsEvent < item.NumberSeats) {
 	return '#00cb7699';
 } else if(item.ClientsEvent >= item.NumberSeats) {
 	return '#ff00238a';
 } else if(item.ClientsEvent === 0) {
 	return '#f7c200d9';
 }
}

return (
	<Panel id={props.id}>
		<PanelHeader before={<PanelHeaderButton onClick={(e) => routeNavigator.push('/photosets/add/')}><Icon28StoryAddOutline /></PanelHeaderButton>} after={props.PanelHeaderAfterProfile}>События</PanelHeader>

		<Group>
			<Search before={isLoading === true ? <Spinner style={{justifyContent: 'unset'}} size="small"/> : <Icon16SearchOutline />} icon={<Icon24Filter  onClick={() => routeNavigator.push('/events/filter', {state: {data: filter, options: data?.request?.options} })} />} onChange={(event) => setFilter({...filter, _search: event.target.value})} />
			<Div>
				<SegmentedControl
					onChange={(value) => setModeView(value)}
          options={[
            {
              'label': 'Списком',
              'value': 'list',
              'aria-label': 'Список',
            },
            {
              'label': 'Таблицей',
              'value': 'grid',
              'aria-label': 'Плитки',
            },
          ]}
        />
      </Div>
      {data?.request?.totalPages > 0 &&<>
	  		<Spacing size={24}><Separator /></Spacing>
				<div style={{display: 'flex', justifyContent: 'space-around'}}>
					 <HorizontalScroll showArrows="false">
						<Pagination onChange={(page) => setCurrentPage(page)} currentPage={currentPage}  totalPages={data?.request?.totalPages} />
					</HorizontalScroll>
				</div></>}
		</Group>

		<>
		{modeView === 'list' ? (
			<Group description={<div style={{marginTop: '17px'}}>{"Найдено " + data?.request?.items?.length + " результатов"}</div>}>
				<HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
					<table width="100%">
						<tbody>

							<tr>
								<td className="Table--TD_head"><Button size="m" mode="link">Город</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Дата</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Запись</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Название</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Фотограф</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">ID</Button></td>
								<td className="Table--TD_head"><Button size="m" disabled mode="link">Операции</Button></td>
							</tr>

							{data?.request?.items?.map((item, key) => {
								return  <tr key={key}>
									<td className="lineTable">{item.City}</td>
									<td className="lineTable">{new Date(item.DateF).toLocaleDateString("ru-RU", { weekday: 'short', month: 'short', day: 'numeric' })}</td>
									<td className="lineTable">{item.Record}</td>
									<td className="lineTable">{item.Title}</td>
									<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.Photographer}</Button></td>
									<td className="lineTable">{item.id}</td>
									<td>
							    	<ButtonGroup mode="horizontal" gap="m">
							    		<IconButton>
							    			<Icon20WriteOutline />
							    		</IconButton>
							    	</ButtonGroup>
			    				</td>
								</tr>
							})}

						</tbody>	
					</table>
				</HorizontalScroll>
			</Group>
		) : (
			<Group>
				<HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
					<table className="VisLine" width="100%">
						<colgroup>
							<col></col>
          	  {groupData.date[0].map((item, key) => {
								return <col key={key} style={new Date(item).getDay()%6==0 ? {background: '#e4ecfd52'} : {}}></col>
							})}
          	</colgroup>

						<tr>
							<td className="Table--TD_head"><Button size="m" mode="link">Фотограф</Button></td>
							{groupData.date[0].map((item, key) => {
								return <td key={key} className="Table--TD_head">{new Date(item).toLocaleDateString("ru-RU", { weekday: 'short', month: 'short', day: 'numeric' })}</td>
							})}
						</tr>
			
						<tbody>
							{groupData?.photographers[0].map((item_0, key_0) => {
								return  <tr key={key_0}>
									<td className="lineTable">{item_0}</td>
									{groupData?.date[0].map((item_1, key_1) => {
										return <td key={key_1} className="lineTable"><div className="PointList_Wrap_Event">{data?.request?.items?.filter(item => item.DateF === item_1 && item.Photographer === item_0).map((item, key) => {
											return <div key={key} style={{background: GetColor(item)}}  className="PointList_Event"></div>
										})}</div></td>
									})}
								</tr>
							})}

						</tbody>	
					</table>
				</HorizontalScroll>
			</Group>
			)}
		</>

		{snackbar}
		{props.Footer}
	</Panel>
)};