import React, { useState  } from 'react';
import { AdaptivityProvider, FormStatus, Button, FormItem, Input, SplitLayout, Spacing, Footer, SplitCol, PanelHeaderButton, Group, PanelHeader, Panel   } from '@vkontakte/vkui';
import { Icon20SunOutline} from '@vkontakte/icons';

//import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const login = (props) => {

const [login, setLogin] = useState('');
const [password, setPassword] = useState('');
const [errorsLogin, setErrorsLogin] = useState('');
const [isButtonLoading, setIsButtonLoading] = useState(false);

function InitLogin() {
	setIsButtonLoading(true);
  	props.api({'login': login, 'password': password}, 'Login').then((REQ) => {
	    setIsButtonLoading(false);
	    if (REQ.status === 200) {
	    	setErrorsLogin(REQ.data?.response?.error);
	    	if (REQ.data?.response?.token !== undefined) {
	    		props.setTokenApi(REQ.data?.response?.token);
	    		localStorage.setItem('STUA', REQ.data?.response?.token);
	    	}
	    }
	});
}

return (
    <SplitLayout popout={props.popout} style={window.screen.width < 1023 ? undefined : {justifyContent: 'center', alignItems: 'center'}} >
      	<SplitCol style={{ 'height': 'unset' }} width="50%" stretchedOnMobile maxWidth={360}>
	      	<Panel>
	        <PanelHeader style={{fontFamily: 'GilroyExtraBold'}} before={<PanelHeaderButton aria-label="" onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')}><Icon20SunOutline/></PanelHeaderButton>}>DoubleFlow | Вход</PanelHeader>
				<Group>
					{errorsLogin?.length > 0 && <FormStatus header="Что-то пошло не так :(" mode="default">
				    	{errorsLogin}
				    </FormStatus>}
		            <FormItem top="Логин">
		              <Input onChange={e => setLogin(e.target.value)} type="text" name="login" />
		            </FormItem>
		            <FormItem top="Пароль">
		              <Input onChange={e => setPassword(e.target.value)} type="password" name="password" />
		            </FormItem>
		            <FormItem>
		              <Button loading={isButtonLoading} onClick={() => InitLogin()} size="l" stretched>
		              	Войти
		              </Button>
		            </FormItem>
				</Group>
             	<Footer>© 2021-2023, ИС ИМПУЛЬС-ТЕХ</Footer>
			</Panel>
      	</SplitCol>
    </SplitLayout>
  );
};

export default login;
