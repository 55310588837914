import React, { useState, useEffect } from "react";
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, ScreenSpinner,Group, RichCell, ButtonGroup, Button, Avatar,  Separator, Search, HorizontalScroll, MiniInfoCell, TabsItem, Tabs, Spacing, Counter, SplitLayout, SplitCol, FormItem, CustomSelect, CustomSelectOption, Checkbox, Pagination  } from '@vkontakte/vkui';
import { Icon20WriteOutline, Icon24Filter, Icon16Dropdown, Icon24FavoriteOutline, Icon16Verified, Icon12Favorite, Icon20WorkOutline, Icon20Info, Icon28SpeedometerMiddleOutline   } from '@vkontakte/icons';

import axios from 'axios';
import moment from 'moment';

export function App(props) {

const routeNavigator = useRouteNavigator();

const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [data, setData] = useState(null);
const [filter, setFilter] = useState([]);
const [snackbar, setSnackbar] = React.useState(null);

useEffect(() => {
	Init();
}, []);


function Init() {
	setIsLoading(true);
	props.popout(<ScreenSpinner></ScreenSpinner>);
	var formData = new FormData();
	formData.append('token', localStorage.getItem('STUA'));
	formData.append('page', currentPage);
	formData.append('filter', JSON.stringify(filter));
	formData.append('model', 'partners'); 
	axios.post('https://impuls200.ru/views/ajax/api/backoffice/GetRecordsModel.php', formData).then((REQ) => {
    if (REQ.status === 200) {
    	setData(REQ.data);
    	setIsLoading(false);
		props.popout();
    }
  });
};

const handleFormChange = (type, event) => {
	switch (type) {
	  case 'text':
	  	setFilter({...filter, [event.target.name]: event.target.value});
	    break;
	  case 'checkbox':
	  	setFilter({...filter, [event.target.name]: event.target.checked});
	    break;
	}
}

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Партнёры</PanelHeader>
		<SplitLayout>
			<SplitCol>
				<Group>
					<Search  />
				</Group>
				<Group>
					<Tabs withScrollToSelectedTab scrollBehaviorToSelectedTab="center">
				        <HorizontalScroll arrowSize="m">
				          <TabsItem status={<Counter mode="secondary" size="s">{data?.request?.items?.length}</Counter>} selected={true}>
				            Все партнёры
				          </TabsItem>
				          <TabsItem status={<Counter mode="secondary" size="s">3</Counter>} selected={false}>
				            Корректоры
				          </TabsItem>
				          <TabsItem status={<Counter mode="secondary" size="s">3</Counter>} selected={false}>
				            Фотографы
				          </TabsItem>
				          <TabsItem status={<Counter mode="secondary" size="s">3</Counter>} selected={false}>
				            Менеджеры
				          </TabsItem>
				        </HorizontalScroll>
				      </Tabs>
				      <Separator />
				      <Spacing size={10}/>
						{data?.request?.items?.map((item, key) => {
						return <>
							<RichCell afterCaption="и другие города" after={item.MainCity} before={<Avatar size={72} src={item.AvaUrl && item.AvaUrl} initials={item.Name[0]+item.Surname[0]}/>} bottom={
								<><MiniInfoCell style={{padding: '6px 0'}} mode="base" before={<Icon20Info />}>
					                {item.TypeUserValue}
					            </MiniInfoCell>
					            <MiniInfoCell style={{padding: '6px 0'}} mode="base" before={<Icon20WorkOutline />}>
					                Работает с {moment(item.DateRegistration).format("DD.MM.YYYY")}, обработано 44 фотосета
					            </MiniInfoCell></>
							}
							actions={
							  <ButtonGroup mode="horizontal" gap="s" stretched>
							  	<Button before={<Icon12Favorite/>} mode="secondary" size="m">5</Button>
							  	<Button before={<Icon28SpeedometerMiddleOutline width={16} height={16}/>} mode="secondary" size="m">Надёжный исполнитель</Button>
							    <Button mode="primary" size="m">
							      Подписаться
							    </Button>
							    <Button size="m" appearance="neutral" mode="tertiary" before={<Icon24FavoriteOutline />}></Button>
							  </ButtonGroup>
							}>
							{item.Name + ' ' + item.Surname + '  '}
							{item.TypeUser === 1 && <Icon16Verified style={{display: 'inline-block',color: 'var(--vkui--color_icon_accent)',verticalAlign: 'text-top'}}/>}
							</RichCell>
							<Spacing size={20} >
							  <Separator />
							</Spacing>
							</>
						})}
					
			    </Group>
		    </SplitCol>

		    <SplitCol autoSpaced maxWidth={320}>
			    <Group>
		      		<FormItem>
					     <CustomSelect value={filter?.["_city"]} onChange={event => handleFormChange('text', event)} name="_city" allowClearButton={true} value={filter?.city} placeholder="Город" searchable options={data?.request?.options?.cities} renderOption={({ option, ...restProps }) => (
					        <CustomSelectOption
					            {...restProps}
					          />
					        )}
					      />
					</FormItem>
		      		<FormItem>
					     <CustomSelect value={filter?.["roles->m2m"]}  onChange={event => handleFormChange('text', event)} name="roles->m2m" allowClearButton={true} value={filter?.city} placeholder="Роль" searchable options={data?.request?.options?.roles} renderOption={({ option, ...restProps }) => (
					        <CustomSelectOption
					            {...restProps}
					          />
					        )}
					      />
					</FormItem>
					<FormItem>
						<Checkbox onChange={event => handleFormChange('checkbox', event)} name="_isCurator">Также является куратором</Checkbox>
					</FormItem>
					<FormItem>
						<Checkbox onChange={event => handleFormChange('checkbox', event)} name="_isFavorite">Показать только избранных</Checkbox>
					</FormItem>
					<FormItem>
					    <Button onClick={() => Init()} size="l" stretched>
						    Найти
						</Button>
				  </FormItem>
			    </Group>

			    {data?.request?.totalPages > 0 && <HorizontalScroll arrowSize="m">
			    	<div style={{display: 'flex', justifyContent: 'space-around'}}>
						<Pagination onChange={(page) => setCurrentPage(page)} currentPage={currentPage}  totalPages={data?.request?.totalPages} />
					</div>
				</HorizontalScroll>}

		    </SplitCol>

	    </SplitLayout>

		{snackbar}
		{props.Footer}

	</Panel>
)};