import '@vkontakte/vkui/dist/vkui.css';
import './App.css';

import { ReactComponent as Logo } from './img/logo.svg';

import React, { useState, useEffect  } from 'react';
import { Root, View, ScreenSpinner, Spinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, Spacing, Link, Switch, PanelSpinner, Footer, IconButton, SplitCol, usePlatform, PanelHeaderButton, SimpleCell, useAdaptivityConditionalRender, ModalCard, ModalRoot, ModalPage, Badge , PanelHeaderBack , ModalPageHeader, Group, CellButton, Platform,PanelHeader, Panel, Cell, Epic, Tabbar, TabbarItem ,Counter , Separator,Avatar, Placeholder, Button, Alert } from '@vkontakte/vkui';
import { Icon28PictureStackOutline, Icon28More, Icon28ListLikeOutline, Icon28AccessibilityOutline, Icon28ClockOutline, Icon28MarketLikeOutline, Icon56EventOutline, Icon28Cards2Outline, Icon28Users3Outline, Icon28GraphOutline, Icon28MessageArrowRightOutline, Icon28Smiles2Outline, Icon28DoorArrowRightOutline, Icon28MoneyHistoryBackwardOutline, Icon28MoneyTransferOutline, Icon28NotebookAddBadgeOutline, Icon28HandHeartOutline, Icon28MessagesOutline } from '@vkontakte/icons';
import { useActiveVkuiLocation, useGetPanelForView, useRouteNavigator } from '@vkontakte/vk-mini-apps-router';

import Login from './Login';

import {App as Photosets_PanelMain} from './panels/Photosets/Main';
import {App as Sorting_PanelMain} from './panels/Sorting/Main';
import {App as Events_PanelMain} from './panels/Events/Main';
import {App as Partners_PanelMain} from './panels/Partners/Main';
import {App as Reports_PanelMain} from './panels/Reports/Main';
import {App as More_PanelMain} from './panels/More/Main';

import {App as Photosets_PanelFiles} from './panels/Photosets/Files';

/**Модалки***/
import {Modal as Photosets_Modal_Add} from './modals/Photosets/Add';
import {Modal as Photosets_Modal_Info} from './modals/Photosets/Info';
import {Modal as Photosets_Modal_SelectedPhotos} from './modals/Photosets/SelectedPhotos';

import {Modal as Events_Modal_Filter} from './modals/Events/Filter';

import {Modal as Profile_Modal} from './modals/Profile';
import {Modal as Profile_Modal_MessageTemplates} from './modals/Profile/MessageTemplates';

import {Modal as Sorting_Modal_Settings} from './modals/Sorting/Settings';
import {Modal as Sorting_Modal_AllReviews} from './modals/Sorting/AllReviews';
import {Modal as Sorting_Modal_Filter} from './modals/Sorting/Filter';
/************/

import axios from 'axios';

const App = (props) => {

	const [userData, setUserData] = useState();
	const [tokenApi, setTokenApi] = useState(localStorage.getItem('STUA') !== null ? localStorage.getItem('STUA') : '');

	const [popout, setPopout] = useState();
	const platform = usePlatform();
	const { viewWidth } = useAdaptivityConditionalRender();

  const {
    view: activeView,
    panel: activePanel,
    root: activeRoot,
    modal: activeModal,
    panelsHistory,
  } = useActiveVkuiLocation(); 

  const routeNavigator = useRouteNavigator();

	useEffect(() => {
		if (activeView === 'login_view') {
			props.setPlatform('vkcom');
		} else {
			props.setPlatform('android');
		}
	}, [activeView]);

  useEffect(() => {
		if (tokenApi.length > 20) {
			if (activeView === 'login_view') {
				routeNavigator.push('/');
			}

			setPopout(<ScreenSpinner></ScreenSpinner>);
			var formData = new FormData();
			formData.append('token', localStorage.getItem('STUA'));
			axios.post('https://impuls200.ru/views/ajax/api/backoffice/accounts/thisUserGet.php', formData).then((REQ) => {
		    if (REQ.status === 200) {
		    	setUserData(REQ.data?.response?.user_data);
					setPopout();

					if (REQ.data?.request?.action === 'user.login') {
						setTokenApi('');
						localStorage.removeItem('STUA');
					}
		    }
		  });

		} else {
			routeNavigator.push('/login');
		}
	}, [tokenApi]);

	function api(data, type) {
    return new Promise(function(resolve, reject) {
	    var formData = new FormData();
			formData.append('token', tokenApi);
			for (const [key, value] of Object.entries(data)) {formData.append(key, value);}
			axios.post('https://impuls200.ru/views/ajax/api/backoffice/'+type+'.php', formData).then((REQ) => {
				if (REQ.data?.request?.errors_modal) {
					setPopout(<Alert actionsLayout="horizontal" header={REQ.data?.request?.errors_modal?.header} onClose={() => setPopout()} text={REQ.data?.request?.errors_modal?.message} />);
				}
				resolve(REQ);
			}).catch((error) => {
		  	setPopout(
			    <Alert
			      actions={[
			        {
			          title: 'Отправить отчёт администратору',
			          autoClose: true,
			          mode: 'destructive',
			          action: () => {},
			        },
			      ]}
			      actionsLayout="horizontal"
			      dismissButtonMode="inside"
			      onClose={() => setPopout()}
			      text={"Возникла неустранимая ошибка: " + error.message + ' ST: ' + error.status}
			    />,
			  );
		  });
	  });
	}

	function logout() {
		setPopout(
      <Alert
        actions={[
          {
            title: 'Отмена',
            autoClose: true,
            mode: 'cancel',
          },
          {
            title: 'Выйти',
            autoClose: true,
            mode: 'destructive',
            action: () => {setTokenApi('');localStorage.removeItem('STUA');},
          },
        ]}
        actionsLayout="horizontal"
        dismissButtonMode="inside"
        onClose={() => setPopout()}
        text="Вы уверены, что хотите выйти из личного кабинета?"
      />,
    );
	}

  const modal = (
    <ModalRoot onClose={() => routeNavigator.hideModal()} activeModal={activeModal}>
    	<Photosets_Modal_Add userData={userData} id="Photosets_Modal_Add" api={api} />
    	<Photosets_Modal_Info id="Photosets_Modal_Info" api={api} />
    	<Photosets_Modal_SelectedPhotos userData={userData} id="Photosets_Modal_SelectedPhotos" api={api} />
    	<Profile_Modal setTokenApi={setTokenApi} userData={userData} id="profile" />
    	<Profile_Modal_MessageTemplates setTokenApi={setTokenApi} userData={userData} id="Profile_Modal_MessageTemplates" />
    	<Events_Modal_Filter userData={userData} id="Events_Modal_Filter" />
    	<Sorting_Modal_Settings userData={userData} id="Sorting_Modal_Settings" api={api} />
    	<Sorting_Modal_Filter userData={userData} id="Sorting_Modal_Filter" />
    	<Sorting_Modal_AllReviews userData={userData} id="Sorting_Modal_AllReviews" />
    </ModalRoot>
  );

	const hasHeader = platform !== Platform.VKCOM;

	const activePanelStyles = {
		backgroundColor: 'var(--vkui--color_background_secondary)',
		borderRadius: 8,
	};

  const onStoryChange = (e) => {() => routeNavigator.push(e.currentTarget.dataset.url)};

	const PanelHeaderAfterProfile = userData && (
    <Avatar onClick={() => routeNavigator.showModal('profile')} size={32} src={userData.ava_url.length > 0 ? userData.ava_url : undefined} initials={userData.name[0]+userData.surname[0]} gradientColor="blue">
    	<Avatar.BadgeWithPreset preset="online"/>
    </Avatar>
	);

	const FooterBox = (
		<Footer>
			© 2021-2024, ИМПУЛЬС.ТЕХНОЛОГИИ, v1.1943
			<Spacing size={16}/>
			<div style={{display: 'flex', justifyContent: 'center', gap: '11px', flexWrap: 'wrap'}}>
     		<Link href="mailto:imp.200@yandex.ru" style={{fontSize: '11px'}}>Техническая поддержка</Link>
     		<Link style={{fontSize: '11px'}}>Правовая информация</Link>
	    </div>
		</Footer>
  );
	
  return (
  	<>
	  	<Root style={{display: activeRoot !== 'login' ? 'none' : 'unset'}} nav="login" activeView={activeView}>
		  	<View nav="login_view" activePanel={activePanel}>
		  		<Login nav='panel_login' viewWidth={viewWidth} popout={popout} theme={props.theme} setTheme={props.setTheme} setUserData={setUserData} setTokenApi={setTokenApi} api={api} />
		  	</View>
	  	</Root>
	  	<Root style={{display: activeRoot !== 'home' ? 'none' : 'unset'}} nav="home" activeView={activeView}>
	  		{tokenApi.length < 20 ? (<Panel nav='home_view'><ScreenSpinner state="loading" /></Panel>) :
					(<SplitLayout nav='home_view' popout={popout} modal={modal} header={hasHeader && <PanelHeader separator={false} />} >
						{viewWidth.tabletPlus && (
			        <SplitCol className={viewWidth.tabletPlus.className} fixed autoSpaced width="15%" >
			          <Panel>
			            <PanelHeader style={{fontFamily: 'Smooch', color: '#fed42b'}}>IMPULS<span style={{color: '#ba9c80'}}>200</span></PanelHeader>
			            <Group>
			              <Cell disabled={activePanel === 'Photosets_PanelMain'} style={activePanel === 'Photosets_PanelMain' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/photosets')} before={<Icon28PictureStackOutline fill="#ba9c80" />}>Фотосеты</Cell>
			              <Cell disabled={activePanel === 'Sorting_PanelMain'} style={activePanel === 'Sorting_PanelMain' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/sorting')} before={<Icon28MarketLikeOutline fill="#ba9c80" />}>Сортировки</Cell>
			              {userData?.scope?.includes(1 || 2 || 3 || 7) && <Cell disabled={activePanel === 'Events_PanelMain'} style={activePanel === 'Events_PanelMain' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/events')} before={<Icon56EventOutline width={28} height={28} fill="#ba9c80" />}>События</Cell>}

			              {userData?.scope?.includes(1 || 2 || 3 || 7) && <Cell disabled={true} style={activePanel === 'persik1' ? activePanelStyles : undefined} data-story="persik" onClick={onStoryChange} before={<Icon28Cards2Outline fill="#ba9c80" />}>Проекты</Cell>}
			              {userData?.scope?.includes(1 || 2) && <Cell disabled={true} style={activePanel === 'persik1' ? activePanelStyles : undefined} data-story="persik" onClick={onStoryChange} before={<Icon28Smiles2Outline fill="#ba9c80" />}>Френд-лист</Cell>}
			              <Cell style={activePanel === 'Partners_PanelMain' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/partners')} before={<Icon28Users3Outline fill="#ba9c80" />}>Партнёры</Cell>
			              {userData?.scope?.includes(1 || 2 || 3) && <Cell disabled={activePanel === 'Reports_PanelMain'} style={activePanel === 'Reports_PanelMain' ? activePanelStyles : undefined} onClick={(e) => routeNavigator.push('/reports')} before={<Icon28GraphOutline fill="#ba9c80" />}>Отчёты</Cell>}
			              <Cell disabled={true} style={activePanel === 'persik1' ? activePanelStyles : undefined} data-story="persik" onClick={onStoryChange} subtitle={"Сервисный сбор: "+userData?.for_payment+"₽"} after={<Counter size="s">{userData?.for_payment}₽</Counter>} before={<Icon28MoneyHistoryBackwardOutline fill="#ba9c80" />}>Мой кошелёк</Cell>
			              <Cell disabled={true} before={<Icon28MessagesOutline fill="#ba9c80" />}>Командный чат</Cell>

			              <CellButton mode="danger" onClick={() => {logout();}} before={<Icon28DoorArrowRightOutline />}>Выйти</CellButton>
			              <Spacing size={16}><Separator /></Spacing>
			              <SimpleCell Component="label" after={<Switch onClick={() => props.setTheme(props.theme == 'dark' ? 'light' : 'dark')} defaultChecked={props.theme === 'dark' ? true : false} />}>Тёмная тема</SimpleCell>
			            </Group>
			          </Panel>
			        </SplitCol>
			      )}
			      <SplitCol width="82%" stretchedOnMobile autoSpaced>
			        <Epic activeStory={activePanel} tabbar={viewWidth.tabletMinus && (
		            <Tabbar className={viewWidth.tabletMinus.className}>
		                <TabbarItem onClick={(e) => routeNavigator.push('/photosets')} selected={activePanel === 'Photosets_PanelMain'} text="Фотосеты">
		                  <Icon28PictureStackOutline />
		                </TabbarItem>
		                <TabbarItem onClick={(e) => routeNavigator.push('/sorting')} selected={activePanel === 'Sorting_PanelMain'} text="Сортировки">
		                  <Icon28MarketLikeOutline />
		                </TabbarItem>
		                <TabbarItem onClick={(e) => routeNavigator.push('/events')} selected={activePanel === 'Events_PanelMain'} text="Расписание событий">
		                  <Icon28ClockOutline />
		                </TabbarItem>
		                <TabbarItem onClick={(e) => routeNavigator.push('/more')} selected={activePanel === 'More_PanelMain'} text="Ещё">
		                  {userData ? <Avatar onClick={() => routeNavigator.showModal('profile')} size={28} src={userData.ava_url.length > 0 ? userData.ava_url : undefined} initials={userData.name[0]+userData.surname[0]} gradientColor="blue"/> : <Icon28More/>}
		                </TabbarItem>
		            </Tabbar>)}>

								<Photosets_PanelMain id="Photosets_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} api={api}  />
								<Sorting_PanelMain id="Sorting_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} Footer={FooterBox} api={api} />
								<Photosets_PanelFiles id="Photosets_PanelFiles" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} api={api} />
								<Events_PanelMain id="Events_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} api={api} />
								<Partners_PanelMain id="Partners_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} api={api} />
								<Reports_PanelMain id="Reports_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} api={api} />
								<More_PanelMain id="More_PanelMain" PanelHeaderAfterProfile={PanelHeaderAfterProfile} popout={setPopout} api={api} userData={userData} />

		        	</Epic>
			      </SplitCol>
			    </SplitLayout>
			  )}
		  </Root>
	  </>
  );
};

export default App;
