import React, { useState, useEffect } from "react";
import { ModalPage, ModalPageHeader, CustomSelect, Text, NativeSelect, FormStatus, Separator, Avatar, CustomSelectOption, Group, Button, Div, FormLayout, FormItem, Input, FormLayoutGroup, Checkbox } from '@vkontakte/vkui';
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';
import { Icon16RoubleOutline } from '@vkontakte/icons';

export function Modal(props) {

	const routeNavigator = useRouteNavigator();
	const [inputFields, setInputFields] = useState({transp_watermark: 1});
	const [data, setData] = useState([]);
	const [name, setName] = useState(undefined);

	const handleFormChange = (type, event) => {
		switch (type) {
		  case 'text':
		  	setInputFields({...inputFields, [event.target.name]: event.target.value});
		  	switch(event.target.name) {
		  		case 'folder_cloud_original':
		  			GetYandexDiskFolderName(event.target.value);
		  			break;
		  	}
		    break;
		  case 'checkbox':
		  	setInputFields({...inputFields, [event.target.name]: event.target.checked});
		    break;
		}
	}

	function GetYandexDiskFolderName (link) {
		if (link) {
			props.api({'link': link, 'method': 'Photoset.Get.YandexFolderName'}, 'GetRecordModel').then((REQ) => {
				if (REQ.status === 200) {
			    	setName(REQ.data?.request?.item?.name);
			    }
			});
		} else {
			setName(undefined);
		}
		
	};

	const sendForm = () => {
		props.api({'data': JSON.stringify(inputFields), 'method': 'Photoset.Add.Object'}, 'AddRecordModel').then((REQ) => {
			if (REQ.status === 200) {
	    		setData(REQ.data?.request);
		    	if (REQ.data?.request?.status === true) {
		    		routeNavigator.push('/photosets', {state: {new_item: REQ.data?.request?.item}});
		    	}
		    }
		});
	};

	function nameToInitials(fullName) {
	  const namesArray = fullName.trim().split(' ');
	  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
	}

	return (
	  <ModalPage header={<ModalPageHeader>{name !== undefined ? name : 'Новый фотосет'}</ModalPageHeader>} id={props.id}>
				<Group>

				{data?.errors?.length > 0 && <Div><FormStatus header="Пару ошибок" mode="error">
	          <div dangerouslySetInnerHTML={{ __html: data?.errors }} />
	        </FormStatus></Div>}

	       <FormLayoutGroup mode="horizontal">
	          <FormItem top="Ссылка на превью">
	            <Input  onChange={event => handleFormChange('text', event)} type="text" name="folder_cloud_original" />
	          </FormItem>
	          <FormItem top="Ссылка на RAW">
	            <Input  onChange={event => handleFormChange('text', event)} type="text" name="yadisk_raw" />
	          </FormItem>
	        </FormLayoutGroup>
	        <FormLayoutGroup mode="horizontal">
	          <FormItem top="Имя клиента">
	            <Input  onChange={event => handleFormChange('text', event)} type="text" name="name_client" />
	          </FormItem>
	          <FormItem top="Телефон клиента">
	            <Input  onChange={event => handleFormChange('text', event)} type="text" name="phone_client" />
	          </FormItem>
	        </FormLayoutGroup>
	        <FormItem top="Оплаченный лимит (шт.)">
	            <Input onChange={event => handleFormChange('text', event)} type="number" name="paid_limit" />
	        </FormItem>

			<FormItem >
	      		<table>
				  <tbody>

					<tr>
						<td className="Table--TD_head">Роль</td>
						<td className="Table--TD_head">За съёмку</td>
						<td className="Table--TD_head">За фото</td>
					</tr>

				    <tr>
				      <th scope="row">
				      <CustomSelect value={inputFields?.manager} onChange={event => handleFormChange('text', event)} name="manager" placeholder="Менеджер" searchable options={props?.userData?.favouriteUsers?.filter((item) => item.roles.includes('2') )} renderOption={({ option, ...restProps }) => (
		            <CustomSelectOption
			              {...restProps}
			              before={<Avatar size={24} src={option.avatar} initials={nameToInitials(option.label)} />}
			            />
			          )}
		          /></th>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="manager_sc" type="number" /></td>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="manager_pc" type="number" /></td>
				    </tr>

				    <tr>
				      <th scope="row">
				      <CustomSelect value={inputFields?.photographer} onChange={event => handleFormChange('text', event)} name="photographer" placeholder="Фотограф" searchable options={props?.userData?.favouriteUsers?.filter((item) => item.roles.includes('3') )} renderOption={({ option, ...restProps }) => (
		            <CustomSelectOption
			              {...restProps}
			              before={<Avatar size={24} src={option.avatar} initials={nameToInitials(option.label)} />}
			            />
			          )}
		          /></th>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="photographer_sc" type="number" /></td>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="photographer_pc" type="number" /></td>
				    </tr>

				    <tr>
				      <th scope="row">
				      <CustomSelect value={inputFields?.proofreader} onChange={event => handleFormChange('text', event)} name="proofreader" placeholder="Корректор" searchable options={props?.userData?.favouriteUsers?.filter((item) => item.roles.includes('4') )} renderOption={({ option, ...restProps }) => (
		            <CustomSelectOption
			              {...restProps}
			              before={<Avatar size={24} src={option.avatar} initials={nameToInitials(option.label)} />}
			            />
			          )}
		          /></th>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="proofreader_sc" type="number" /></td>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="proofreader_pc" type="number" /></td>
				    </tr>

				    <tr>
				      <th scope="row">
				      <CustomSelect value={inputFields?.organizer} onChange={event => handleFormChange('text', event)} name="organizer" placeholder="Организатор" searchable options={props?.userData?.favouriteUsers?.filter((item) => item.roles.includes('7') )} renderOption={({ option, ...restProps }) => (
		            <CustomSelectOption
			              {...restProps}
			              before={<Avatar size={24} src={option.avatar} initials={nameToInitials(option.label)} />}
			            />
			          )}
		          /></th>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="organizer_sc" type="number"/></td>
				      <td><Input after={<Icon16RoubleOutline />} onChange={event => handleFormChange('text', event)} name="organizer_pc" type="number" /></td>
				    </tr>

				    <tr>
				      <th scope="row"></th>
				      <td>{Number(inputFields?.manager_sc) + Number(inputFields?.photographer_sc) + Number(inputFields?.proofreader_sc) + Number(inputFields?.organizer_sc)}</td>
				      <td>{Number(inputFields?.manager_pc) + Number(inputFields?.photographer_pc) + Number(inputFields?.proofreader_pc) + Number(inputFields?.organizer_pc)}</td>
				    </tr>

				  </tbody>
				</table>
			</FormItem>

	  		<FormItem top="Интенсивность штампа">
		        <NativeSelect name="transp_watermark" onChange={event => handleFormChange('text', event)}>
		          <option value="1">Слабый 8%</option>
		          <option value="2">Средний 25%</option>
		          <option value="3">Не прозрачный 60%</option>
		        </NativeSelect>
		    </FormItem>

			<FormItem>
	        	<Checkbox name="is_exif_date" onChange={event => handleFormChange('checkbox', event)}>Добавить время на превью</Checkbox>
	        </FormItem>

			<FormItem>
	          <Button onClick={() => sendForm()} size="l" stretched>
	          	Добавить фотосет
	          </Button>
	        </FormItem>

	      </Group>
	  </ModalPage>
	)
};