import React, { useState, useEffect } from "react";
import { useParams } from '@vkontakte/vk-mini-apps-router';
import { ModalPage, ModalPageHeader, RichCell, Avatar, MiniInfoCell, Button, FormItem, SimpleCell, Separator, Spacing, IconButton, Group, Link } from '@vkontakte/vkui';
import { Icon24Add, Icon20CommunityName, Icon20MessagesOutline, Icon24LinkedOutline } from '@vkontakte/icons';
import axios from 'axios';

export function Modal(props) {

const params = useParams('uuid');

const [data, setData] = useState([]);

useEffect(() => {
  props.api({'id': params.uuid, 'method': 'Photoset.Get.Info'}, 'GetRecordModel').then((REQ) => {
    if (REQ.status === 200) {
      setData(REQ.data?.request?.item);
    }
  });
}, []);

function nameToInitials(fullName) {
  const namesArray = fullName.trim().split(' ');
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

return (
  <ModalPage dynamicContentHeight={true} hideCloseButton={true} header={<ModalPageHeader>{data?.title?.length > 0 ? data?.title : 'Ожидание...'}</ModalPageHeader>} id={props.id}>
      {data?.id?.length > 0 && <>
        <Group>
          {data?.raw && <MiniInfoCell before={<Icon24LinkedOutline />}>
            RAW: <Link href={data?.raw}>{data?.raw}</Link>
          </MiniInfoCell>}
          {data?.jpg && <MiniInfoCell before={<Icon24LinkedOutline />}>
            JPG: <Link href={data?.jpg}>{data?.jpg}</Link>
          </MiniInfoCell>}
        
          <SimpleCell expandable indicator={data?.paid_limit + ' фото'}>
            Оплаченный лимит (шт.)
          </SimpleCell>
          <SimpleCell expandable indicator={data?.price_unit + '₽'}>
            Командный сбор
          </SimpleCell>
          <SimpleCell expandable indicator={data?.org_collection + '₽'}>
            Орг. сбор
          </SimpleCell>
          <SimpleCell expandable indicator={(data?.price_unit+data?.org_collection) + '₽'}>
            Сумма за фото
          </SimpleCell>
          <FormItem>
            <Button size="l" stretched>
              Перезапустить фотосет
            </Button>
          </FormItem>
        </Group>

        {data?.team?.manager && <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.manager?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={nameToInitials(data?.team?.manager?.name + ' ' + data?.team?.manager?.surname)} gradientColor="violet" />} caption={"Менеджер" + ' · ' + data?.team?.manager?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.manager?.surname + ' ' +data?.team?.manager?.name}
        </RichCell>}
        {data?.team?.proofreader &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.proofreader?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={nameToInitials(data?.team?.proofreader?.name + ' ' + data?.team?.proofreader?.surname)} gradientColor="green" />} caption={"Корректор"  + ' · ' + data?.team?.proofreader?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.proofreader?.surname + ' ' +data?.team?.proofreader?.name}
        </RichCell>}
        {data?.team?.organizer &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.organizer?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={nameToInitials(data?.team?.organizer?.name + ' ' + data?.team?.organizer?.surname)} gradientColor="l-blue" />} caption={"Организатор" + ' · ' + data?.team?.organizer?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.organizer?.surname + ' ' +data?.team?.organizer?.name}
        </RichCell>}
        {data?.team?.photographer &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.photographer?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={nameToInitials(data?.team?.photographer?.name + ' ' + data?.team?.photographer?.surname)} gradientColor="orange" />} caption={"Фотограф" + ' · ' + data?.team?.photographer?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.photographer?.surname + ' ' +data?.team?.photographer?.name}
        </RichCell>}

        <Separator />

        <Group padding="s" description={"ID: " + data?.id} separator="hide"></Group>
        <Group padding="s" description={"UUID: " + data?.uuid} separator="hide"></Group>
      </>}

  </ModalPage>
)};