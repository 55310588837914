import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, Button, Checkbox, Spinner, Snackbar, SegmentedControl, Text, Tappable, FixedLayout, Tabs, Spacing, FormStatus, TabsItem, Separator, Counter, ScreenSpinner, PanelHeaderContent, ActionSheet, PanelHeaderContext, ActionSheetItem, Pagination, Link, HorizontalScroll, FormItem, Group, Cell, Div, Search, Avatar, Title, SimpleCell,IconButton, PanelHeaderButton,ButtonGroup, Subhead, ListWrapper, HorizontalCell, SubnavigationBar, SubnavigationButton } from '@vkontakte/vkui';
import { unstable_RichTooltip as RichTooltip } from '@vkontakte/vkui';
import { Icon20WriteOutline, Icon16Done, Icon28MessageOutline, Icon20AddCircleOutline, Icon24UserSquareOnSquareOutline, Icon20CopyOutline, Icon16DoneCircle, Icon16Dropdown  } from '@vkontakte/icons';
import { Icon20CancelCircleFillRed, Icon28ClockOutline, Icon28Notifications, Icon16Favorite, Icon28StoryAddOutline, Icon24Filter, Icon20CheckCircleOff, Icon12Chevron } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [modeView, setModeView] = useState('');
const [contextOpened, setContextOpened] = useState(false);
const [selectedItems, setSelectItem] = useState([]);
const [data, setData] = useState(null);
const [filter, setFilter] = useState([]);
const [snackbar, setSnackbar] = React.useState(null);
const targetRefs = [];

useEffect(() => {
	Init();
}, []);

useEffect(() => {
	if (data !== null) {
		if (params?.new_item?.id > 0) {
			setData(prevState => ({request: { ...prevState.request, items: [params?.new_item, ...prevState.request.items]}}));
			routeNavigator.push('/photosets');
		}
	}
}, [params]);

useEffect(() => {
	setIsLoading(true);
	props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'photosets'}, 'GetRecordsModel').then((REQ) => {
    if (REQ.status === 200) {
    	setData(REQ.data);
    	setIsLoading(false);
			props.popout();
    }
	});
}, [filter, currentPage]);

function Init() {
	setIsLoading(true);
	props.popout(<ScreenSpinner></ScreenSpinner>);
	props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'photosets'}, 'GetRecordsModel').then((REQ) => {
    if (REQ.status === 200) {
    	setData(REQ.data);
    	setIsLoading(false);
			props.popout();
    }
	});
};

function GetStatus(id) {
	switch (id) {
	  case 1:
	  	return 'В работе';
	    break;
	  case 2:
	  	return 'Архив';
	    break;
	  case 3:
	  	return 'Новые';
	    break;
	  case 4:
	  	return 'Завершенные';
	    break;
	}
}

const CopyText = (text) => {
	navigator.clipboard.writeText(text);
  if (snackbar) return;
  setSnackbar(
    <Snackbar
    	subtitle={text}
      onClose={() => setSnackbar(null)}
      before={
        <Avatar size={24} style={{ background: 'var(--vkui--color_background_accent)' }}>
          <Icon16Done fill="#fff" width={14} height={14} />
        </Avatar>
      }
    >
      Ссылка скопирована
    </Snackbar>,
  );
};

const GetPSIcon = (idS, id) => {
	switch (idS) {
	  case 1:
	  	return <Icon28ClockOutline width={20} height={20} />;
	    break;
	  case 2:
	  	return <Icon16DoneCircle style={selectedItems.indexOf(id) > -1 && {color: 'var(--vkui--color_icon_accent)'}} onClick={() => selectItem(id)} width={20} height={20}/>;
	    break;
	  case 3:
	  	return <Icon20CancelCircleFillRed width={19} height={19}/>;
	    break;
	  case 4:
	  	return <Spinner size="small" style={{ justifyContent: 'unset' }}/>;
	    break;
	}
}

const selectItem = (id) => {
	if (selectedItems.indexOf(id) > -1) {
		setSelectItem([...selectedItems.slice(0, selectedItems.indexOf(id)), ...selectedItems.slice(selectedItems.indexOf(id) + 1)]);
	} else {
		setSelectItem(oldArray => [...oldArray, id]);
	}
};

const ChangeStatus = (UUID, status) => {
	props.api({'id': UUID, 'status': status, 'method': 'Photoset.Edit.Status'}, 'ModifyRecordModel').then((REQ) => {
		if (REQ.status === 200) {
			setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
		   item.UUID === UUID ? { ...item, Status: REQ.data?.request?.item.status} : item
		 	)}}));
    }
  });
}

const openBase = (key, UUID, Status) => {
  props.popout(
    <ActionSheet popupOffsetDistance="-40" onClose={() => props.popout(null)} toggleRef={targetRefs[key].current}>
      <ActionSheetItem onClick={() => ChangeStatus(UUID, 1)} autoClose selectable defaultChecked={Status === 1}>В работе</ActionSheetItem>
      <ActionSheetItem onClick={() => ChangeStatus(UUID, 2)} autoClose selectable defaultChecked={Status === 2}>Архив</ActionSheetItem>
      <ActionSheetItem onClick={() => ChangeStatus(UUID, 3)} autoClose selectable defaultChecked={Status === 3}>Новые</ActionSheetItem>
      <ActionSheetItem onClick={() => ChangeStatus(UUID, 4)} autoClose selectable defaultChecked={Status === 4}>Завершенные</ActionSheetItem>
    </ActionSheet>,
  );
}

return (
	<Panel id={props.id}>
		<PanelHeader before={<PanelHeaderButton onClick={(e) => routeNavigator.push('/photosets/add/')}><Icon28StoryAddOutline /></PanelHeaderButton>} after={props.PanelHeaderAfterProfile}>
			<PanelHeaderContent
	        aside={
	          <Icon16Dropdown
	            style={{
	              transform: `rotate(${contextOpened ? '180deg' : '0'})`,
	            }}
	          />
	        }
	        onClick={() => setContextOpened(true)}
	      >
	      Фотосеты
	    </PanelHeaderContent>
		</PanelHeader>

		<PanelHeaderContext opened={contextOpened} onClose={() => setContextOpened(false)}>
      <SimpleCell onClick={() => {setModeView('in_archive'); setFilter({...filter, _mode: 'in_archive'})}} data-mode="all">
        В архиве
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('new'); setFilter({...filter, _mode: 'new'})}} indicator={<Counter mode={modeView === 'new' ? "primary" : "secondary"}>{data?.request?.count?.new}</Counter>} data-mode="all">
        Новые
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('in_work'); setFilter({...filter, _mode: 'in_work'})}} indicator={<Counter mode={modeView === 'in_work' ? "primary" : "secondary"}>{data?.request?.count?.in_work}</Counter>} data-mode="all">
        В работе
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('completed'); setFilter({...filter, _mode: 'completed'})}} data-mode="all">
        Завершенные
      </SimpleCell>
    </PanelHeaderContext>

		<Group>
      <Search icon={<Icon24Filter />} onChange={(event) => setFilter({...filter, _search: event.target.value})} />
	    {data?.request?.LastResponseProcessingServer && <Div><FormStatus header="Отклик сервера" mode="default">
	        Последний отклик сервера обработки изображений: {data?.request?.LastResponseProcessingServer}
	    </FormStatus></Div>}
			{data?.request?.totalPages > 1 &&
				<>
					<Spacing size={24}><Separator /></Spacing>
					<div style={{display: 'flex', justifyContent: 'space-around'}}>
						<HorizontalScroll showArrows="false">
							<Pagination onChange={(page) => setCurrentPage(page)} currentPage={currentPage}  totalPages={data?.request?.totalPages} />
						</HorizontalScroll>
					</div>
				</>
			}
    </Group>

		<Group description={<div style={{marginTop: '17px'}}>{"Найдено " + data?.request?.items?.length + " результатов"}</div>}>
				<HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
					<table width="100%">
						<tbody>

							<tr>
								<td className="Table--TD_head">{isLoading === true ? <Spinner style={{justifyContent: 'unset'}} size="small"/> : <Icon16DoneCircle width={20} height={20}/>}</td>
								<td className="Table--TD_head"><Icon16Favorite width={20} height={20}/></td>
								<td className="Table--TD_head"><Button size="m" mode="link">ID</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Имя проекта</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Статус</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Дата фотосессии</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Контакты</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Сортировки</Button></td>
								<td className="Table--TD_head"><Button size="m" disabled mode="link">Операции</Button></td>
							</tr>

							{/*data?.request?.items?.length === 0 && Array.from(Array(10)).map((item, key) => {
							return <tr>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton borderRadius="100%" width="25px" height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
								<td><SkeletonTheme baseColor="#202020" highlightColor="#444"><Skeleton height="25px"/></SkeletonTheme></td>
							</tr>})*/}

							{data?.request?.items?.map((item, key) => {
								targetRefs.push(React.createRef())
								return  <tr key={key}>
									<td className="lineTable">{GetPSIcon(item.StatusProject, item.id)}</td>
									<td className="lineTable">{item.Rating}</td>
									<td onClick={(e) => routeNavigator.push('/photosets/info/'+item.UUID)} className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.id}</Button></td>
									<td className="lineTable">{item.Name}</td>
									<td className="lineTable" key={key} ref={targetRefs[key]} onClick={() => openBase(key, item.UUID, item.Status)}><Button mode="outline" appearance={item.Status === 1 ? "positive" : item.Status === 3 ? "accent-invariable" : "neutral"} rounded="true" size="s">{GetStatus(item.Status)}</Button></td>
									<td className="lineTable">{item.Date}</td>
									<td className="lineTable">
										{item.PhoneClient.length > 3 ? <Button mode="link" appearance="neutral" href={'whatsapp://send?phone=' + item.PhoneClient}>{item.PhoneClient.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}</Button> : "-"}
									</td>
									<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.CountCustomers}</Button></td>
									<td>
							    	<ButtonGroup mode="horizontal" gap="m">
							    		<IconButton>
							    			<Icon20WriteOutline width="20" height="20"/>
							    		</IconButton>
							    		<IconButton onClick={(e) => routeNavigator.push('/photosets/SelectedPhotos/'+item.UUID)}>
							    			<Icon24UserSquareOnSquareOutline width="20" height="20"/>
							    		</IconButton>
							    		<IconButton onClick={() => {CopyText('https://office.impuls200.ru/bind/'+item.UID)}}>
							    			<Icon20CopyOutline width="20" height="20"/>
							    		</IconButton>
							    	</ButtonGroup>
			    				</td>
								</tr>
							})}

						</tbody>	
					</table>
				</HorizontalScroll>

		</Group>

		{snackbar}
		{props.Footer}

	</Panel>
)};