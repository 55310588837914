import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, FormItem, Input, Button, DateInput, CustomSelect, CustomSelectOption, Separator,FormLayoutGroup, LocaleProvider } from '@vkontakte/vkui';
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';
import { Icon24MoneyCircleOutline } from '@vkontakte/icons';
import moment  from 'moment';

import axios from 'axios';

export function Modal(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [filter, setFilter] = useState(params?.data);

const handleFormChange = (type, event) => {
	switch (type) {
	  case 'text':
	  	setFilter({...filter, [event.target.name]: event.target.value});
	    break;
	  case 'checkbox':
	  	setFilter({...filter, [event.target.name]: event.target.checked});
	    break;
	}
}

return (
  <ModalPage header={<ModalPageHeader>Фильтр</ModalPageHeader>} id={props.id}>

  	<Separator />

  	<FormItem>
	    <Input placeholder="Общий поиск ID | Имя " defaultValue={filter?._search} onChange={event => handleFormChange('text', event)} type="text" name="_search" />
		</FormItem>
		<FormItem>
	    <Input placeholder="Имя события" defaultValue={filter?.["name->like"]} onChange={event => handleFormChange('text', event)} type="text" name="name->like" />
		</FormItem>
		<FormItem>
      <CustomSelect allowClearButton={true} value={filter?.city} onChange={event => handleFormChange('text', event)} name="city" placeholder="Город" searchable options={params?.options?.cities} renderOption={({ option, ...restProps }) => (
        <CustomSelectOption
            {...restProps}
          />
        )}
      />
		</FormItem>
		<FormLayoutGroup mode="horizontal" >
			<FormItem top="Дата события с">
	      <DateInput value={filter?.["date>="] ? new Date(filter?.["date>="]) : null} onChange={val => setFilter({...filter, "date>=": val ? moment(val).format('YYYY-MM-DD') : ''})} closeOnChange={true} />
	    </FormItem>
	    <FormItem top="Дата события по">
				<DateInput value={filter?.["date<="] ? new Date(filter?.["date<="]) : null} onChange={val => setFilter({...filter, "date<=": val ? moment(val).format('YYYY-MM-DD') : ''})} closeOnChange={true} />
	    </FormItem>
	  </FormLayoutGroup>

		<FormItem>
      <Button onClick={() => routeNavigator.push('/events', {state: {filter: filter}})} size="l" stretched>
	    	Применить
	    </Button>
		</FormItem>

  </ModalPage>
)};
