import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Group, MiniInfoCell, Title, CellButton, SimpleCell, Avatar, Gradient, Counter, Cell } from '@vkontakte/vkui';
import { Icon24Add, Icon28DoorArrowRightOutline, Icon28MessagePinOutline, Icon20MentionOutline,Icon28CheckShieldOutline, Icon28Users3Outline, Icon28MoneyHistoryBackwardOutline, Icon28Smiles2Outline , Icon28GraphOutline ,Icon28MessagesOutline, Icon28Cards2Outline } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const styles = {
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 32,
  background: 'url(https://vktech.production.vklanding.ru/img/machine.webp)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '9px',
};

return (
	<Panel id={props.id}>
		<PanelHeader after={props.PanelHeaderAfterProfile}>Профиль</PanelHeader>
			<Gradient mode="tint" to='top' style={styles}>
		      <Avatar size={96} src={props.userData?.ava_url?.length > 0 ? props.userData.ava_url : undefined} initials={props.userData?.name[0]+props.userData?.surname[0]} gradientColor="blue">
		      	<Avatar.BadgeWithPreset preset="online" />
		      	<Avatar.Overlay>
		          <Icon24Add width={32} height={32} />
		        </Avatar.Overlay>
		      </Avatar>
		      <Title style={{ marginBottom: 8, marginTop: 20 }} level="2" weight="2">
		        {props.userData?.name + ' ' + props.userData?.surname}
		      </Title>
		    </Gradient>

		    <Group>
				{props?.userData?.scope?.includes(1 || 2 || 3 || 7) && <Cell disabled={true} before={<Icon28Cards2Outline fill="#ba9c80" />}>Проекты</Cell>}
				{props?.userData?.scope?.includes(1 || 2) && <Cell disabled={true} before={<Icon28Smiles2Outline fill="#ba9c80" />}>Френд-лист</Cell>}
				<Cell onClick={(e) => routeNavigator.push('/partners')} before={<Icon28Users3Outline fill="#ba9c80" />}>Партнёры</Cell>
				{props?.userData?.scope?.includes(1 || 2 || 3) && <Cell onClick={(e) => routeNavigator.push('/reports')} before={<Icon28GraphOutline fill="#ba9c80" />}>Отчёты</Cell>}
				<Cell disabled={true} subtitle={"Сервисный сбор: "+props?.userData?.for_payment+"₽"} after={<Counter size="s">{props?.userData?.for_payment}₽</Counter>} before={<Icon28MoneyHistoryBackwardOutline fill="#ba9c80" />}>Мой кошелёк</Cell>
				<Cell disabled={true} before={<Icon28MessagesOutline fill="#ba9c80" />}>Командный чат</Cell>
				<CellButton onClick={() => {props.setTokenApi(''); localStorage.removeItem('STUA')}} before={<Icon28DoorArrowRightOutline />} mode="danger">Выйти</CellButton>
		    </Group>

		{props.Footer}
	</Panel>
)};