import React, { useState, useEffect } from "react";
import { useParams } from '@vkontakte/vk-mini-apps-router';
import { ModalPage, ModalPageHeader, Separator, RichCell, MiniInfoCell, Avatar, UsersStack, Search, SubnavigationButton, SubnavigationBar } from '@vkontakte/vkui';
import { Icon16Done, Icon20FavoriteOutline, Icon24FavoriteOutline } from '@vkontakte/icons';
import axios from 'axios';

export function Modal(props) {

const params = useParams('uuid');
const [data, setData] = useState([]);

useEffect(() => {
  var formData = new FormData();
  formData.append('token', localStorage.getItem('STUA'));
  formData.append('id', params.uuid);
  formData.append('method', 'Sorting.Get.Info');
  axios.post('https://impuls200.ru/views/ajax/api/backoffice/GetRecordModel.php', formData).then((REQ) => {
    if (REQ.status === 200) {
      setData(REQ.data?.request?.item);
    }
  });
}, []);


return (
  <ModalPage dynamicContentHeight={true} header={<ModalPageHeader>{data?.PhoneClient?.length > 0 ? 'Сортировка #'+data?.id : 'Ожидание...'}</ModalPageHeader>} id={props.id}>
    <Separator />
      <Search  />

      <SubnavigationBar>
                <SubnavigationButton selected={true}>
                  Мой размер
                </SubnavigationButton>

                <SubnavigationButton
                  selected={false}
                >
                  В наличии
                </SubnavigationButton>

                <SubnavigationButton
                  selected={false}
                >
                  Высокий рейтинг
                </SubnavigationButton>

                <SubnavigationButton
                  before={<Icon24FavoriteOutline />}
                  selected={false}
                >
                  Избранное
                </SubnavigationButton>
              </SubnavigationBar>

      <RichCell afterCaption="сегодня в 18:00" before={<Avatar size={72} initials="КМ" />} caption="тут я оставил отзыв"
        bottom={
          <UsersStack
            photos={[
              <Avatar initials="КМ" size={48} />,
              <Avatar initials="КМ" size={48} />,
              <Avatar initials="КМ" size={48} />,
            ]}
          >
            Ср. 5
          </UsersStack>
        }
      >
        Илья Гришин
      </RichCell>

            <RichCell afterCaption="сегодня в 18:00" before={<Avatar size={72} initials="КМ" />} caption="тут я оставил отзыв"
        bottom={
          <UsersStack
            photos={[
              <Avatar initials="КМ" size={48} />,
              <Avatar initials="КМ" size={48} />,
              <Avatar initials="КМ" size={48} />,
            ]}
          >
            Ср. 5
          </UsersStack>
        }
      >
        Илья Гришин
      </RichCell>
  </ModalPage>
)};