import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, Gradient, Avatar, Title, Group, SimpleCell, CellButton, Header, MiniInfoCell } from '@vkontakte/vkui';
import { Icon24Add, Icon28SchoolOutline, Icon28AddOutline, Icon28DoorArrowRightOutline, Icon20MentionOutline, Icon28MessagePinOutline, Icon28CheckShieldOutline } from '@vkontakte/icons';
import { useRouteNavigator } from '@vkontakte/vk-mini-apps-router';
//import axios from 'axios';

export function Modal(props) {

const styles = {
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 32,
  background: 'url(https://vktech.production.vklanding.ru/img/machine.webp)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: '9px',
};

const routeNavigator = useRouteNavigator();

return (
  <ModalPage id={props.id}>
		<Gradient mode="tint" to='top' style={styles}>
      <Avatar size={96} src={props.userData?.ava_url?.length > 0 ? props.userData.ava_url : undefined} initials={props.userData?.name[0]+props.userData?.surname[0]} gradientColor="blue">
      	<Avatar.BadgeWithPreset preset="online" />
      	<Avatar.Overlay>
          <Icon24Add width={32} height={32} />
        </Avatar.Overlay>
      </Avatar>
      <Title style={{ marginBottom: 8, marginTop: 20 }} level="2" weight="2">
        {props.userData?.name + ' ' + props.userData?.surname}
      </Title>
    </Gradient>
    <Group mode="plain">
      <MiniInfoCell before={<Icon20MentionOutline />} textWrap="full">{props.userData?.nickname}</MiniInfoCell>
      <SimpleCell onClick={() => routeNavigator.showModal('Profile_Modal_MessageTemplates')} before={<Icon28MessagePinOutline />} subtitle="">
        Шаблоны сообщений
      </SimpleCell>
      <SimpleCell before={<Icon28CheckShieldOutline />} subtitle="">
        Активные сеансы
      </SimpleCell>
      <CellButton onClick={() => {props.setTokenApi(''); localStorage.removeItem('STUA')}} before={<Icon28DoorArrowRightOutline />} mode="danger">
        Выйти
      </CellButton>
    </Group>
  </ModalPage>
)};