import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { RouterProvider, RouteWithRoot, createBrowserRouter } from '@vkontakte/vk-mini-apps-router';
import { AdaptivityProvider, AppRoot, ConfigProvider } from '@vkontakte/vkui';
import App from "./App";

const routes: RouteWithRoot[] = [
  {
    path: '/',            // Путь
    panel: 'Photosets_PanelMain',  // Желаемый Panel
    view: 'home_view', // Желаемый View
    root: 'home', // Желаемый Root
  },
  {
    path: '/photosets',            // Путь
    panel: 'Photosets_PanelMain',  // Желаемый Panel
    view: 'home_view', // Желаемый View
    root: 'home', // Желаемый Root
  },
  {
    path: '/sorting',            // Путь
    panel: 'Sorting_PanelMain',  // Желаемый Panel
    view: 'home_view', // Желаемый View
    root: 'home', // Желаемый Root
  },
  {
    path: '/sorting/info/:uuid',
    panel: 'Sorting_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Sorting_Modal_Settings'
  },
  {
    path: '/sorting/allReviews',
    panel: 'Sorting_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Sorting_Modal_AllReviews'
  },
  {
    path: '/sorting/filter',
    panel: 'Sorting_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Sorting_Modal_Filter'
  },
  {
    path: '/events', // Events__home_view
    panel: 'Events_PanelMain',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/partners', // Partners__home_view
    panel: 'Partners_PanelMain',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/reports', // Events__home_view
    panel: 'Reports_PanelMain',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/more', // Events__home_view
    panel: 'More_PanelMain',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/events/filter',
    panel: 'Events_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Events_Modal_Filter'
  },
  {
    path: '/photosets/info/:uuid',
    panel: 'Photosets_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Photosets_Modal_Info'
  },
  {
    path: '/photosets/SelectedPhotos/:uuid',
    panel: 'Photosets_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Photosets_Modal_SelectedPhotos'
  },
  {
    path: '/photosets/files/:uuid',
    panel: 'Photosets_PanelFiles',
    view: 'home_view',
    root: 'home',
  },
  {
    path: '/photosets/add',
    panel: 'Photosets_PanelMain',
    view: 'home_view',
    root: 'home',
    modal: 'Photosets_Modal_Add'
  },
  {
    path: '/login',            // Путь
    panel: 'panel_login',  // Желаемый Panel
    view: 'login_view', // Желаемый View
    root: 'login', // Желаемый Root
  }
];

const router = createBrowserRouter(routes);

const Index = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'dark');
  const [platform, setPlatform] = useState('vkcom');

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ConfigProvider hasCustomPanelHeaderAfter={false} platform={platform} appearance={theme}>
      <AdaptivityProvider hasPointer={true}>
        <AppRoot>
          <RouterProvider notFound={<p>Ничегошеньки!</p>} router={router}>
            <App theme={theme} setPlatform={setPlatform} setTheme={setTheme} />
          </RouterProvider>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  );
}

ReactDOM.render(<Index />, document.getElementById('root'));

if (process.env.NODE_ENV === "development") {
  import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
