import React, { useState, useEffect, useCallback, createRef } from "react";
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';

import { Panel, PanelHeader, Header, Button, Spinner, Spacing, Separator, Counter, Alert, ScreenSpinner, PanelHeaderButton, Badge, PanelHeaderContent, ActionSheet, PanelHeaderContext, ActionSheetItem, Pagination, Link, HorizontalScroll, Group, MiniInfoCell, Search, SimpleCell, IconButton, ButtonGroup, HorizontalCell } from '@vkontakte/vkui';
import { unstable_RichTooltip as RichTooltip } from '@vkontakte/vkui';
import { Icon20WriteOutline, Icon16Done, Icon12PercentOutline, Icon24UserSquareOnSquareOutline, Icon16SearchOutline, Icon12Chevron, Icon20UnarchiveOutline, Icon28FavoriteOutline, Icon28SettingsOutline, Icon16DoneCircle, Icon16Dropdown, Icon24Filter, Icon20MoneyCircleOutline, Icon24MessageForwardOutline, Icon20ArchiveOutline  } from '@vkontakte/icons';

export function App(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [modeView, setModeView] = useState('');
const [contextOpened, setContextOpened] = useState(false);
const [selectedItems, setSelectItem] = useState([]);
const [data, setData] = useState(null);
const [filter, setFilter] = useState([]);

useEffect(() => {
	Init();
}, []);

useEffect(() => {
	setFilter(params?.filter);
}, [params]);

useEffect(() => {
	setIsLoading(true);
	props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'sorting'}, 'GetRecordsModel').then((REQ) => {
	  if (REQ.status === 200) {
	  	setData(REQ.data);
	  	setIsLoading(false);
			props.popout();
	  }
  });
}, [filter, currentPage]);

function Init() {
	setIsLoading(true);
	props.popout(<ScreenSpinner></ScreenSpinner>);
	props.api({'page': currentPage, 'filter': JSON.stringify(filter), 'model': 'sorting'}, 'GetRecordsModel').then((REQ) => {
	  if (REQ.status === 200) {
	  	setData(REQ.data);
	  	setIsLoading(false);
			props.popout();
	  }
	});
};

const selectItem = (id) => {
	if (selectedItems.indexOf(id) > -1) {
		setSelectItem([...selectedItems.slice(0, selectedItems.indexOf(id)), ...selectedItems.slice(selectedItems.indexOf(id) + 1)]);
	} else {
		setSelectItem(oldArray => [...oldArray, id]);
	}
};

const ChangeStatusPay = (UUID) => {
	setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
		item.UUID === UUID ? { ...item, IsPaid: item.IsPaid === 0 ? 1 : 0} : item
	)}}));
	// props.api({'id': UUID, 'status': status, 'method': 'Photoset.Edit.Status'}, 'ModifyRecordModel').then((REQ) => {
	// 	if (REQ.status === 200) {
	// 		setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
	// 	   item.UUID === UUID ? { ...item, Status: REQ.data?.request?.item.status} : item
	// 	 	)}}));
  //   }
  // });
}

const ChangeStatusDone = (UUID) => {
	setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
		item.UUID === UUID ? { ...item, IsDone: item.IsDone === 0 ? 1 : 0} : item
	)}}));
	// props.api({'id': UUID, 'status': status, 'method': 'Photoset.Edit.Status'}, 'ModifyRecordModel').then((REQ) => {
	// 	if (REQ.status === 200) {
	// 		setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
	// 	   item.UUID === UUID ? { ...item, Status: REQ.data?.request?.item.status} : item
	// 	 	)}}));
  //   }
  // });
}

function ActionArchive(type, cli_name, UUID, action) {
	if (action === 'req') {
		props.popout(
	    <Alert
	      actions={[
	        {
	          title: 'Отмена',
	          autoClose: true,
	          mode: 'cancel',
	        },
	        {
	          title: type === 'send' ? 'В архив' : 'Вернуть из архива',
	          autoClose: true,
	          mode: 'destructive',
	          action: () => {ActionArchive(type, cli_name, UUID, 'confirmation')},
	        },
	      ]}
	      actionsLayout="horizontal"
	      dismissButtonMode="inside"
	      onClose={() => props.popout()}
	      text={type === 'return' ? "Вы уверены, что хотите вернуть сортировку ("+cli_name+") из архива ?" : "Вы уверены, что хотите отправить сортировку ("+cli_name+") в архив ?"}
	    />,
	  );		
	} else if(action === 'confirmation') {
		setData(prevState => ({request: { ...prevState.request, items: prevState.request.items.map(item =>
			item.UUID === UUID ? { ...item, IsArchive: item.IsArchive === 0 ? 1 : 0} : item
		)}}));
	}

}


function GetColorTag(id) {
  switch (id) {
      case 1:
          return "#ff18008c";
          break;
      case 2:
          return "#ffeb00";
          break;
      case 3:
          return "#00b8ff";
          break;
      case 4:
          return "#b400ff";
          break;
      case 5:
          return "#ffa500";
          break;
      case 6:
          return "unset";
          break;
      case 7:
          return "#00ff43";
          break;
      default:
          return 'unset';
          break;
  }
}


return (
	<Panel id={props.id}>
		<PanelHeader before={<PanelHeaderButton onClick={(e) => routeNavigator.push('/sorting/allReviews/')}><Icon28FavoriteOutline /></PanelHeaderButton>} after={props.PanelHeaderAfterProfile}>
			<PanelHeaderContent
	        aside={
	          <Icon16Dropdown
	            style={{
	              transform: `rotate(${contextOpened ? '180deg' : '0'})`,
	            }}
	          />
	        }
	        onClick={() => setContextOpened(true)}
	      >
	      Сортировки
	    </PanelHeaderContent>
		</PanelHeader>

		<PanelHeaderContext opened={contextOpened} onClose={() => setContextOpened(false)}>
      <SimpleCell onClick={() => {setModeView('forgotten'); setFilter({...filter, _mode: 'forgotten'})}} indicator={<Counter mode={modeView === 'forgotten' ? "primary" : "secondary"}>{data?.request?.count?.forgotten}</Counter>} data-mode="all">
        Забытые
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('for_payment'); setFilter({...filter, _mode: 'for_payment'})}} indicator={<Counter mode={modeView === 'for_payment' ? "primary" : "secondary"}>{data?.request?.count?.for_payment}</Counter>} data-mode="all">
        На оплату
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('for_processing'); setFilter({...filter, _mode: 'for_processing'})}} indicator={<Counter mode={modeView === 'for_processing' ? "primary" : "secondary"}>{data?.request?.count?.for_processing}</Counter>} data-mode="all">
        На обработку
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('completed'); setFilter({...filter, _mode: 'completed'})}} indicator={<Counter mode={modeView === 'completed' ? "primary" : "secondary"}>{data?.request?.count?.completed}</Counter>} data-mode="all">
        Отдать клиенту
      </SimpleCell>
      <SimpleCell onClick={() => {setModeView('in_archive'); setFilter({...filter, _mode: 'in_archive'})}} data-mode="all">
        Архив
      </SimpleCell>
    </PanelHeaderContext>

		<Group>
      <Search before={isLoading === true ? <Spinner style={{justifyContent: 'unset'}} size="small"/> : <Icon16SearchOutline />} icon={<Icon24Filter  onClick={() => routeNavigator.push('/sorting/filter', {state: {data: filter, options: data?.request?.options} })} />} onChange={(event) => setFilter({...filter, _search: event.target.value})} />
			
			{data?.request?.totalPages > 1 &&
				<>
					<Spacing size={24}><Separator /></Spacing>
					<div style={{display: 'flex', justifyContent: 'space-around'}}>
						<HorizontalScroll showArrows="false">
							<Pagination onChange={(page) => setCurrentPage(page)} currentPage={currentPage}  totalPages={data?.request?.totalPages} />
						</HorizontalScroll>
					</div>
				</>
			}
    </Group>

		<Group description={<div style={{marginTop: '17px'}}>{"Найдено " + data?.request?.items?.length + " результатов"}</div>}>
				<HorizontalScroll  getScrollToLeft={(i) => i - 120} getScrollToRight={(i) => i + 120}>
					<table width="100%">
						<tbody>

							<tr>
								<td className="Table--TD_head">{isLoading === true ? <Spinner style={{justifyContent: 'unset'}} size="small"/> : <Icon16DoneCircle width={20} height={20}/>}</td>
								<td className="Table--TD_head"><Button size="m" mode="link">ID</Button></td>
								<td className="Table--TD_head"></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Фотосет</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Имя</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Телефон</Button></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Кол.</Button></td>
								<td className="Table--TD_head"></td>
								<td className="Table--TD_head"></td>
								<td className="Table--TD_head"></td>
								<td className="Table--TD_head"><Button size="m" mode="link">Доплата</Button></td>
								<td className="Table--TD_head"><Button size="m" disabled mode="link">Операции</Button></td>
							</tr>

							{data?.request?.items?.map((item, key) => {
								return  <tr key={key}>
									<td className="lineTable"><Icon16DoneCircle style={selectedItems.indexOf(item.id) > -1 && {color: 'var(--vkui--color_icon_accent)'}} onClick={() => selectItem(item.id)} width={20} height={20}/></td>
									<td className="lineTable">{item.id}</td>
									<td className="lineTable" style={{position: 'sticky', left: '0px'}}><div className="TD--ColorTagCustom" style={{background: GetColorTag(item.ColorTag)}}></div></td>
									<td className="lineTable">{item.IsFeedback === true ? <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{item.Photoset} <Badge style={{marginLeft: '13px'}}/></span > : item.Photoset}</td>
									<td className="lineTable"><Button mode="link" appearance="neutral" after={<Icon12Chevron/>}>{item.Name}</Button></td>
									<td className="lineTable">
										{item.Phone.length > 3 ? <Button mode="link" appearance={item.Status === 4 ? "negative" : "neutral"} href={'whatsapp://send?phone=' + item.Phone}>{item.Phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}</Button> : "-"}
									</td>
									<td className="lineTable">{item.Count + ' из ' + item.PaidLimit}</td>
									<td className="lineTable"><Icon12PercentOutline width="20" height="20" color={item.IsDiscount > 0 && '#4bb34b'} /></td>
									<td className="lineTable"><IconButton><Icon20MoneyCircleOutline onClick={() => ChangeStatusPay(item.UUID)} color={item.IsPaid === 1 && '#4bb34b'} /></IconButton></td>
									<td className="lineTable"><IconButton><Icon16DoneCircle onClick={() => ChangeStatusDone(item.UUID)} width="20" height="20" color={item.IsDone === 1 && '#4bb34b'} /></IconButton></td>
									<td className="lineTable">{item.AddPayment} ₽</td> 
									<td>
							    	<ButtonGroup mode="horizontal" gap="m">
							    		<IconButton >
							    			<Icon24MessageForwardOutline width="20" height="20" />
							    		</IconButton>
							    		{item.IsArchive === 0 ? <IconButton onClick={() => ActionArchive('send', item.Name, item.UUID, 'req')} disabled={item.IsPaid === 1 && true}>
							    			<Icon20ArchiveOutline />
							    		</IconButton> : <IconButton onClick={() => ActionArchive('return', item.Name, item.UUID, 'req')}>
							    			<Icon20UnarchiveOutline />
							    		</IconButton>}
							    		<IconButton onClick={(e) => routeNavigator.push('/sorting/info/'+item.UUID)}>
							    			<Icon28SettingsOutline width="20" height="20" />
							    		</IconButton>
							    	</ButtonGroup>
			    				</td>
								</tr>
							})}

						</tbody>	
					</table>
				</HorizontalScroll>

		</Group>

		{props.Footer}

	</Panel>
)};