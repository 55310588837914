import React, { useState, useEffect } from "react";
import { useParams } from '@vkontakte/vk-mini-apps-router';
import { ModalPage, ModalPageHeader, RichCell, Avatar, Textarea, MiniInfoCell, Button, Text, SimpleCell, Separator, Spacing, IconButton, Group, Link } from '@vkontakte/vkui';
import { Icon16CopyOutline } from '@vkontakte/icons';

export function Modal(props) {

const params = useParams('uuid');

const [loading, setLoading] = useState(false);
const [data, setData] = useState([]);

useEffect(() => {
  setLoading(true);
  props.api({'uuid': params.uuid}, 'photosets/SelectedPhotos').then((REQ) => {
    if (REQ.status === 200) {
      setData(REQ.data);
      setLoading(false);
    }
  });
}, []);

return (
  <ModalPage dynamicContentHeight={true} hideCloseButton={true} header={<ModalPageHeader>{loading === false ? 'Выбранные фото' : 'Ожидание...' }</ModalPageHeader>} id={props.id}>
      {loading === false && <><Separator />
      <Group>
        <MiniInfoCell after={<Icon16CopyOutline />} textWrap="full"><span style={{whiteSpace: 'break-spaces'}}>{data?.data?.res}</span></MiniInfoCell>
      </Group></>}
  </ModalPage>
)};