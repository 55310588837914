import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import { ModalPage, ModalPageHeader, FormItem, Input, Button, DateInput, CustomSelect, CustomSelectOption, Checkbox, Separator,FormLayoutGroup, LocaleProvider } from '@vkontakte/vkui';
import { useRouteNavigator, useMetaParams } from '@vkontakte/vk-mini-apps-router';
import { Icon24MoneyCircleOutline } from '@vkontakte/icons';
import moment  from 'moment';

import axios from 'axios';

export function Modal(props) {

const routeNavigator = useRouteNavigator();
const params = useMetaParams();

const [filter, setFilter] = useState(params?.data);

const handleFormChange = (type, event) => {
	switch (type) {
	  case 'text':
	  	setFilter({...filter, [event.target.name]: event.target.value});
	    break;
	  case 'checkbox':
	  	setFilter({...filter, [event.target.name]: event.target.checked});
	    break;
	}
}

return (
  <ModalPage header={<ModalPageHeader>Фильтр</ModalPageHeader>} id={props.id}>
  	<FormItem>
	    <Input placeholder="Общий поиск ID | Имя " defaultValue={filter?._search} onChange={event => handleFormChange('text', event)} type="text" name="_search" />
	  </FormItem>
	  <FormItem>
	    <Input placeholder="Телефон" defaultValue={filter?._search} onChange={event => handleFormChange('text', event)} type="text" name="_search" />
		</FormItem>

		<FormItem>
	    <Input placeholder="Имя события" defaultValue={filter?.["name->like"]} onChange={event => handleFormChange('text', event)} type="text" name="name->like" />
		</FormItem>
		<FormItem>
      <CustomSelect allowClearButton={true} value={filter?.city} onChange={event => handleFormChange('text', event)} name="city" placeholder="Фотосет" searchable options={params?.options?.cities} renderOption={({ option, ...restProps }) => (
        <CustomSelectOption
            {...restProps}
          />
        )}
      />
		</FormItem>

		<FormItem>
	    <Checkbox name="is_exif_date" onChange={event => handleFormChange('checkbox', event)}>В архиве</Checkbox>
	    <Checkbox name="is_exif_date" onChange={event => handleFormChange('checkbox', event)}>Оплаченые</Checkbox>
	    <Checkbox name="is_exif_date" onChange={event => handleFormChange('checkbox', event)}>Выполненые</Checkbox>
	    <Checkbox name="is_exif_date" onChange={event => handleFormChange('checkbox', event)}>Со скидкой</Checkbox>
	  </FormItem>

		<FormItem>
      <Button onClick={() => routeNavigator.push('/sorting', {state: {filter: filter}})} size="l" stretched>
	    	Применить
	    </Button>
		</FormItem>

  </ModalPage>
)};
