import React, { useState, useEffect } from "react";
import { useParams } from '@vkontakte/vk-mini-apps-router';
import { ModalPage, ModalPageHeader, Separator, Group, FormItem, SegmentedControl, Textarea, Input, SubnavigationBar, RichCell,Counter, Div, IconButton, Link, FormStatus, MiniInfoCell, SubnavigationButton, Button, Snackbar, Avatar } from '@vkontakte/vkui';
import { Icon16Done, Icon20MessagesOutline, Icon16CopyOutline, Icon20MessageOutline, Icon12Favorite } from '@vkontakte/icons';

export function Modal(props) {

const params = useParams('uuid');
const [data, setData] = useState([]);
const [modeView, setModeView] = useState("contacts");

useEffect(() => {
  props.api({'id': params.uuid, 'method': 'Sorting.Get.Info'}, 'GetRecordModel').then((REQ) => {
    if (REQ.status === 200) {
      setData(REQ.data?.request?.item);
    }
  });
}, []);

function SaveData() {

}

return (
  <ModalPage dynamicContentHeight={true} header={<ModalPageHeader>{data?.PhoneClient?.length > 0 ? 'Сортировка #'+data?.id : 'Ожидание...'}</ModalPageHeader>} id={props.id}>
    {data?.PhoneClient?.length > 0 && <Group>
     <FormItem>
        <SegmentedControl size="m" onChange={(value) => setModeView(value)} defaultValue="contacts" options={[
            {
              label: 'Контакты',
              value: 'contacts',
            },
            {
              label: 'Логи',
              value: 'logs',
            },
            {
              label: 'Скидка',
              value: 'discount',
            },
            {
              label: 'Фото',
              value: 'photo',
            },
          ]}
        />
      </FormItem>

      {modeView === "contacts" && (
        <>
        {data?.IsUsingPhotos === true && <Div><FormStatus>
          Клиент разрешил использовать выбранные фото для потфолио по согласованию.
        </FormStatus></Div>}
        {data?.PhoneClient && <RichCell bottom={data?.CommentClient && data?.CommentClient} after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.PhoneClient}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={data?.NameClient[0]+data?.SurnameClient[0]} gradientColor="red"/>} caption={"Клиент" + ' · ' + data?.PhoneClient.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
            {data?.SurnameClient + ' ' +data?.NameClient}
        </RichCell>}
        <Separator />
        {data?.team?.manager && <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.manager?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={data?.team?.manager?.name[0]+data?.team?.manager?.surname[0]} gradientColor="violet"/>} caption={"Менеджер" + ' · ' + data?.team?.manager?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.manager?.surname + ' ' +data?.team?.manager?.name} 
          {data?.ratingPhotographer > 0 && <Counter mode="contrast" size="s" style={{display: 'inline-flex', alignItems: 'center', marginLeft: '7px'}}><div style={{display: 'inline-flex', alignItems: 'center'}}><Icon12Favorite fill="#ffbc00" style={{marginRight: '4px'}}/> <span style={{marginRight: '3px'}}>{data?.ratingPhotographer}</span></div></Counter>}
        </RichCell>}
        {data?.team?.photographer &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.photographer?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={data?.team?.photographer?.name[0]+data?.team?.photographer?.surname[0]} gradientColor="l-blue" />} caption={"Фотограф" + ' · ' + data?.team?.photographer?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.photographer?.surname + ' ' +data?.team?.photographer?.name}
          {data?.ratingPhotographer > 0 && <Counter mode="contrast" size="s" style={{display: 'inline-flex', alignItems: 'center', marginLeft: '7px'}}><div style={{display: 'inline-flex', alignItems: 'center'}}><Icon12Favorite fill="#ffbc00" style={{marginRight: '4px'}}/> <span style={{marginRight: '3px'}}>{data?.ratingPhotographer}</span></div></Counter>}
        </RichCell>}
        {data?.team?.proofreader &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.proofreader?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={data?.team?.proofreader?.name[0]+data?.team?.proofreader?.surname[0]} gradientColor="green" />} caption={"Корректор"  + ' · ' + data?.team?.proofreader?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.proofreader?.surname + ' ' +data?.team?.proofreader?.name}
        </RichCell>}
        {data?.team?.organizer &&  <RichCell disabled after={<Link target="_blank" href={'whatsapp://send?phone=' + data?.team?.organizer?.phone}><RichCell.Icon aria-hidden><Icon20MessagesOutline /></RichCell.Icon></Link>} before={<Avatar size={38} initials={data?.team?.organizer?.name[0]+data?.team?.organizer?.surname[0]} gradientColor="l-blue" />} caption={"Организатор" + ' · ' + data?.team?.organizer?.phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")}>
          {data?.team?.organizer?.surname + ' ' +data?.team?.organizer?.name}
        </RichCell>}
        
        </>
      ) || modeView === "logs" && (
      <>
        <FormItem top="Лог сортировки">
          <Textarea value={data?.Log} />
        </FormItem>
        <FormItem top="Лог ошибок">
          <Textarea value={data?.LogsErrors} />
        </FormItem>
      </>
      ) || modeView === "discount" && (
      <>
        <FormItem top="Размер скидки">
           <Input key={data.Discount ? 'notLoadedYet_1' : 'loaded_1'} defaultValue={data.Discount} />
        </FormItem>
        <FormItem>
          <Button size="l" stretched>
            Сохранить
          </Button>
        </FormItem>
      </>
      ) || modeView === "photo" && (
      <>
        <FormItem top="Ссылка на папку с фото">
           <Input type="text" key={data.LinkFolderJpg ? 'notLoadedYet_2' : 'loaded_2'} defaultValue={data.LinkFolderJpg} />
        </FormItem>
        <FormItem top="Ссылка на готовые фото">
           <Input value={data.LinkDone} after={
            <IconButton hoverMode="opacity" label="Очистить поле">
              <Icon16CopyOutline />
            </IconButton>
          } />
        </FormItem>
        <Separator />
         <MiniInfoCell after={<Icon16CopyOutline />} textWrap="full"><span style={{whiteSpace: 'break-spaces'}}>{data.ResultSorting}</span></MiniInfoCell>
        <FormItem>
          <Button size="l" stretched>
            Сохранить
          </Button>
        </FormItem>
      </>
      )}

      
    </Group>}

  </ModalPage>
)};